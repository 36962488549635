export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/user/__layout.svelte"),
	() => import("../../src/routes/ggvec.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/interactive-recommender.svelte"),
	() => import("../../src/routes/pymde_3d_40n.svelte"),
	() => import("../../src/routes/pymde_4d_100n.svelte"),
	() => import("../../src/routes/pymde_4d_40n.svelte"),
	() => import("../../src/routes/recommendation/training/train.svelte"),
	() => import("../../src/routes/user/[username]/atlas.svelte"),
	() => import("../../src/routes/user/[username]/recommendations.svelte"),
	() => import("../../src/routes/user/[username]/stats.svelte")
];

export const dictionary = {
	"": [[0, 4], [1]],
	"ggvec": [[0, 3], [1], 1],
	"interactive-recommender": [[0, 5], [1], 1],
	"pymde_3d_40n": [[0, 6], [1], 1],
	"pymde_4d_100n": [[0, 7], [1], 1],
	"pymde_4d_40n": [[0, 8], [1], 1],
	"recommendation/training/train": [[0, 9], [1]],
	"user/[username]/atlas": [[0, 2, 10], [1], 1],
	"user/[username]/recommendations": [[0, 2, 11], [1], 1],
	"user/[username]/stats": [[0, 2, 12], [1], 1]
};